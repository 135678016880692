import React, { useState, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import Texts from '../Common/Texts';
import TextTab from '../Common/TextTab';
import Table from '../Common/Table';
import {
  subtractMonthsAndGetFirstDay,
  getDateStringWithSlash,
} from '../../utils/helpers';
import Loading from '../Loading';
import { GET_DATA_CHARGE_HISTORY, MAX_CHARGE_HISTORY } from './constants';

const ChargeHistory = () => {
  // queryの引数に使用するrefDate。 rerenderで置き換わらないようuseMemoで保存。
  const refDate = useMemo(() => new Date(), []);
  const [displayMonthIndex, setDisplayMonthIndex] = useState(0);
  const [getDataChargeHistory, { data, loading }] = useLazyQuery(
    GET_DATA_CHARGE_HISTORY
  );

  useEffect(() => {
    const firstDateOfTargetMonth = subtractMonthsAndGetFirstDay(
      refDate,
      displayMonthIndex
    );
    const lastDateOfTargetMonth = new Date(
      new Date(
        subtractMonthsAndGetFirstDay(refDate, displayMonthIndex - 1)
      ).setDate(0)
    ).toISOString();
    getDataChargeHistory({
      variables: {
        chargeHistoryStartDate: firstDateOfTargetMonth,
        chargeHistoryEndDate: lastDateOfTargetMonth,
        sortKey: 'data_charge_datetime',
        sortOrder: 'DESC',
        pageSize: MAX_CHARGE_HISTORY,
        pageNo: 1,
      },
    });
  }, [getDataChargeHistory, displayMonthIndex, refDate]);

  return (
    <>
      <Texts.H3>データチャージ履歴</Texts.H3>
      <Texts.Body2>
        毎月最大10件までのデータチャージ履歴を表示します。
      </Texts.Body2>
      <TextTab>
        {['今月', '前月'].map((month, monthIndex) =>
          monthIndex === displayMonthIndex ? (
            <div key={`month_tab_${monthIndex}`}>{month}</div>
          ) : (
            <button
              key={`month_tab_${monthIndex}`}
              onClick={() => setDisplayMonthIndex(monthIndex)}
            >
              {month}
            </button>
          )
        )}
      </TextTab>
      <Table.Container>
        {loading ? (
          <Loading />
        ) : get(data, 'getDataChargeHistory.records.length') ? (
          // @ts-ignore
          get(data, 'getDataChargeHistory.records').map((item) => (
            <Table.Row key={item.id}>
              <Table.Key>
                {getDateStringWithSlash(new Date(item.dateApplied))}
              </Table.Key>
              <Table.Value>
                {item.isCanceled ? 'データチャージ失敗' : item.name}
              </Table.Value>
              <Table.ValueRight>
                {item.isCanceled
                  ? '-'
                  : `+${Math.floor(item.capacity / 1024).toLocaleString()}GB`}
              </Table.ValueRight>
            </Table.Row>
          ))
        ) : (
          <Table.Row>チャージ履歴がありません</Table.Row>
        )}
      </Table.Container>
    </>
  );
};

export default ChargeHistory;
