import React from 'react';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typo } from '../../styles';
import { device } from '../../styles/constants';

const STEPS = 5;

const Container = styled.div`
  overflow-x: auto;
  margin-top: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 420px;
  height: 373px;
  padding: 36px 24px 16px;
  background: ${({ theme }) => theme.barChart.background};
  border-radius: 10px;

  @media ${device.mobile} {
    height: 283px;
    padding: 24px 16px 24px;
  }
`;

const BackGround = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Step = styled.div`
  flex: 1;
  border-top: solid 1px ${({ theme }) => theme.barChart.border};
  :last-child {
    border-bottom: solid 2px ${({ theme }) => theme.barChart.border};
  }
`;

const BarContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`;

const Bar = styled.div`
  position: relative;
  width: 40px;
  // @ts-ignore
  height: ${({
    // @ts-ignore
    height,
  }) => `${height}%`};
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.barChart.gradient.end} 0%,
    ${({ theme }) => theme.barChart.gradient.start} 100%
  );
  border-radius: 5px 5px 0px 0px;

  > div {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    color: ${({ theme }) => theme.text.special};
    ${typo.XXS};
  }
`;

const XAxis = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  color: ${({ theme }) => theme.text.tertiary};
  ${typo.XS};
  text-align: center;
  > div {
    flex: 1;
  }

  @media ${device.mobile} {
    margin-top: 8px;
  }
`;

// @ts-ignore
// @ts-ignore
// @ts-ignore
const BarChart = ({ items, unit, maxY }) => {
  // @ts-ignore
  const xAxis = items.map(item => item.x);
  return (
    <Container>
      <Content>
        <BackGround>
          <BarContainer>
            {items.map(
              // @ts-ignore
              item =>
                item.y === undefined ? (
                  // @ts-ignore
                  <Bar
                    key={`bar_${item.x}`}
                    // @ts-ignore
                    height={0}
                  />
                ) : (
                  <Bar
                    // @ts-ignore
                    key={`bar_${item.x}`}
                    // @ts-ignore
                    height={maxY > 0 ? (item.y / maxY) * 100 : 0}
                  >
                    <div>{`${
                      // @ts-ignore
                      item.y.toLocaleString()
                    }${unit}`}</div>
                  </Bar>
                )
            )}
          </BarContainer>
          {range(STEPS).map(step => (
            <Step key={`step_${step}`} />
          ))}
        </BackGround>
        <XAxis>
          {/* @ts-ignore */}
          {xAxis.map(
            // @ts-ignore
            x => (
              <div key={`x_${x}`}>{x}</div>
            )
          )}
        </XAxis>
      </Content>
    </Container>
  );
};

BarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.number,
    })
  ).isRequired,
  unit: PropTypes.string.isRequired,
  maxY: PropTypes.number.isRequired,
};

export default BarChart;
