import { gql } from '@apollo/client';

export const MAX_CHARGE_HISTORY = 10;

export const GET_DATA_CHARGE_HISTORY = gql`
  query getDataChargeHistory(
    $chargeHistoryStartDate: DateTime!
    $chargeHistoryEndDate: DateTime!
    $sortKey: String
    $sortOrder: SortOrder
    $pageSize: Int
    $pageNo: Int
  ) {
    getDataChargeHistory(
      startDate: $chargeHistoryStartDate
      endDate: $chargeHistoryEndDate
      pageSpecifier: {
        sortKey: $sortKey
        sortOrder: $sortOrder
        pageSize: $pageSize
        pageNo: $pageNo
      }
    ) {
      records {
        id
        name
        capacity
        dateApplied
        isCanceled
      }
    }
  }
`;
