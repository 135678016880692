import styled, { css } from 'styled-components';
import { typo } from '../../styles';
import { device } from '../../styles/constants';

const Ul = styled.ul`
  :not(:first-child) {
    margin-top: 8px;
  }
`;

const LiCss = css`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.listItem.text.primary};
  ${typo.S};
  > * {
    flex: 1 1 auto;
  }

  @media ${device.mobile} {
    > * {
      width: 100%;
    }
    flex-direction: column;
    > *:not(:first-child) {
      margin-top: 16px;
    }
  }
`;

const Li = styled.li`
  ${LiCss};
`;

const BorderLiCss = css`
  ${LiCss};
  padding: 16px 0 16px 16px;
  border-top: 1px solid ${({ theme }) => theme.listItem.border};
  :last-child {
    border-bottom: 1px solid ${({ theme }) => theme.listItem.border};
  }
  @media ${device.mobile} {
    padding: 16px 0;
  }
`;

const BorderLi = styled.li`
  ${BorderLiCss};
`;

const CenterLi = styled.div`
  ${LiCss};
  align-items: center;

  @media ${device.mobile} {
    align-items: initial;
  }
`;

const CenterBorderLi = styled.div`
  ${BorderLiCss};
  align-items: center;

  @media ${device.mobile} {
    align-items: initial;
  }
`;

const FlexContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -16px;
  margin-right: -40px;

  > * {
    margin-bottom: 16px;
    margin-right: 40px;
  }
`;

const RightContent = styled.div`
  text-align: right;
`;

const BottomRightContent = styled.div`
  align-self: flex-end;
  text-align: right;
`;

const Title1 = styled.div`
  ${typo.XXL_B};
  white-space: nowrap;
`;

const Title2 = styled.div`
  ${typo.L_B};
`;

const Title3 = styled.div`
  ${typo.M_B};
`;

const Title4 = styled.div`
  ${typo.L_B};
  color: ${({ theme }) => theme.listItem.text.secondary};
`;

const Title5 = styled.div`
  ${typo.M};
`;

const Text1 = styled.div`
  margin-top: 8px;
  ${typo.M};
  line-height: 1.5;
`;

const Text2 = styled.div`
  margin-top: 16px;
  ${typo.S};
  line-height: 1.5;
  white-space: pre-wrap;
`;

const Text3 = styled.div`
  margin-top: 16px;
  ${typo.XS};
  line-height: 1.5;
  white-space: pre-wrap;
`;
const ListItem = {
  Ul,
  Li,
  BorderLi,
  CenterLi,
  CenterBorderLi,
  FlexContent,
  RightContent,
  BottomRightContent,
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  Text1,
  Text2,
  Text3,
};

export default ListItem;
