import styled from 'styled-components';
import { typo } from '../../styles';
import { device } from '../../styles/constants';

const TextTab = styled.div`
  display: flex;
  ${typo.S};
  :not(:first-child) {
    margin-top: 16px;
  }
  > *:not(:first-child) {
    margin-left: 16px;
  }
  > button {
    text-decoration: underline;
    color: ${({ theme }) => theme.text.special};
  }

  @media ${device.mobile} {
    ${typo.XS};
  }
`;

export default TextTab;
