import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get, range, chunk } from 'lodash';
import Link from 'next/link';
import { useUserContext } from '../../context/UserContext';
import MenuLayout from '../../components/Layout/MenuLayout';
import Form from '../../components/Common/Form';
import Texts from '../../components/Common/Texts';
import Table from '../../components/Common/Table';
import Boxes from '../../components/Common/Boxes';
import Button from '../../components/Common/Button';
import ListItem from '../../components/Common/ListItem';
import RemainingVolume from '../../components/RemainingVolume';
import BarChart from '../../components/BarChart';
import ChargeHistory from '../../components/ChargeHistory';
import {
  convertMBtoGB,
  subtractDays,
  subtractMonthsAndGetFirstDay,
  getDateStringWithSlash,
} from '../../utils/helpers';
import Loading from '../../components/Loading';
import { GET_DATA_USAGE_INFO } from '../../constants';

const IndexPage = () => {
  const { accountStatus, userInfoLoading } = useUserContext();

  // queryの引数に使用するrefDate。 rerenderで置き換わらないようuseMemoで保存。
  const refDate = useMemo(() => new Date(), []);

  const [displayUsageTermIndex, setDisplayUsageTermIndex] = useState(0);

  const [getDataUsageInfo, { data, error, loading }] =
    useLazyQuery(GET_DATA_USAGE_INFO);

  useEffect(() => {
    // 画面ロード時に必要な情報を取得
    if (accountStatus?.hasActivePlan) {
      getDataUsageInfo({
        variables: {
          // 昨日〜28日前の日別データ利用履歴
          usageStartDate: subtractDays(refDate, 28),
          usageEndDate: subtractDays(refDate, 1),
          // 先々月〜今月の月別データ利用履歴
          monthlyUsageStartDate: subtractMonthsAndGetFirstDay(refDate, 2),
          monthlyUsageEndDate: subtractMonthsAndGetFirstDay(refDate, 0),
        },
      });
    }
  }, [accountStatus, getDataUsageInfo, refDate]);

  const monthlyUsageHistory = useMemo(() => {
    const usageHistory = get(data, 'getDataUsageHistory', []);
    // usageHistoryの中で、今月・先月・先々月のデータを探して返す
    return range(3).map((index) => {
      return (
        // @ts-ignore
        usageHistory.find((month) => {
          return (
            new Date(month.date).getMonth() ===
            new Date(subtractMonthsAndGetFirstDay(refDate, index)).getMonth()
          );
        }) || null
      );
    });
  }, [data, refDate]);

  const weeklyUsageHistory = useMemo(() => {
    const usageHistory =
      get(data, 'getUser.mobileServiceAccount.dataUsageInfo.usageHistory') ||
      [];
    const termList = chunk(usageHistory, 7).map((term) => {
      // usageHistoryを7日ごとのtermに区切る
      const endDate = new Date(term[0].date);
      const endDateString = getDateStringWithSlash(endDate);
      // termが7日に満たない場合でも7日分のデータを生成する
      const startDate = new Date(endDate.setDate(endDate.getDate() - 6));
      const startDateString = getDateStringWithSlash(startDate);

      return {
        termString: `${startDateString}〜${endDateString}`,
        list: range(7).map((index) => {
          const newestDate = new Date(term[0].date);
          // // APIが返す履歴は日付が新しい順のため、各termで古い順に並び替える
          const reverseIndex = 6 - index;
          newestDate.setDate(newestDate.getDate() - reverseIndex);
          // 日本時間の日付をM/Dで表示する
          const dateString = new Date(newestDate).toLocaleDateString('ja-JP', {
            day: 'numeric',
            month: 'numeric',
            timeZone: 'Asia/Tokyo',
          });
          return {
            x: dateString,
            y: term[reverseIndex] ? term[reverseIndex].usage : undefined,
          };
        }),
      };
    });
    // 取得したすべての履歴の中で最大の利用量を取得する
    const maxRecord = usageHistory.reduce(
      // @ts-ignore
      // @ts-ignore
      (acc, cur) => (acc > cur.usage ? acc : cur.usage),
      0
    );
    return { termList, maxRecord };
  }, [data]);

  if (error) {
    throw error;
  }
  const upperLimit = get(
    data,
    'getUser.mobileServiceAccount.autoDataChargeSetting.upperLimit'
  );
  const monthlyDataChargeValue = get(
    data,
    'getUser.mobileServiceAccount.autoDataChargeSetting.monthlyDataChargeValue'
  );

  return (
    <MenuLayout>
      {(userInfoLoading || loading) && <Loading />}
      <Texts.H1>データ利用状況</Texts.H1>
      {accountStatus?.hasActivePlan ? (
        data && (
          <>
            <Texts.H2>データ量</Texts.H2>
            <Boxes.White>
              <RemainingVolume
                volume={get(
                  data,
                  'getUser.mobileServiceAccount.dataUsageInfo.currentRemainingCapacity'
                )}
              />
              <ListItem.Ul style={{ marginTop: '32px' }}>
                <ListItem.CenterBorderLi>
                  <div>
                    <ListItem.Title5>オートチャージ設定</ListItem.Title5>
                    <ListItem.Text3>
                      {`+${upperLimit}GB${
                        upperLimit > 0 ? 'まで/月' : '（利用しない）'
                      }`}
                      {upperLimit > 0 && (
                        <Texts.WarningSpan style={{ marginLeft: '8px' }}>
                          {monthlyDataChargeValue}GBオートチャージ済み
                        </Texts.WarningSpan>
                      )}
                    </ListItem.Text3>
                  </div>
                  <ListItem.BottomRightContent>
                    <Link href="/mypage/charge/auto">
                      <Button.Tertiary>変更</Button.Tertiary>
                    </Link>
                  </ListItem.BottomRightContent>
                </ListItem.CenterBorderLi>
              </ListItem.Ul>
              <ChargeHistory />
              <Texts.Note>※100GB以上所持することはできません</Texts.Note>
            </Boxes.White>
            <Texts.H2>データ利用履歴</Texts.H2>
            <Boxes.White>
              <Texts.H3>最近のデータ通信量</Texts.H3>
              <Table.Container>
                <Table.Row>
                  <Table.Key>
                    <Texts.Strong>今月分の合計</Texts.Strong>
                  </Table.Key>
                  <Table.ValueRight>
                    <Texts.Strong>{`${
                      monthlyUsageHistory[0]
                        ? convertMBtoGB(monthlyUsageHistory[0].usage)
                        : '-'
                    } GB`}</Texts.Strong>
                  </Table.ValueRight>
                </Table.Row>
                <Table.Row>
                  <Table.Key>前月</Table.Key>
                  <Table.ValueRight>{`${
                    monthlyUsageHistory[1]
                      ? convertMBtoGB(monthlyUsageHistory[1].usage)
                      : '-'
                  } GB`}</Table.ValueRight>
                </Table.Row>
                <Table.Row>
                  <Table.Key>前々月</Table.Key>
                  <Table.ValueRight>{`${
                    monthlyUsageHistory[2]
                      ? convertMBtoGB(monthlyUsageHistory[2].usage)
                      : '-'
                  } GB`}</Table.ValueRight>
                </Table.Row>
              </Table.Container>
              <Texts.H3>利用履歴詳細</Texts.H3>
              {get(weeklyUsageHistory, 'termList.length') ? (
                <>
                  {/* @ts-ignore */}
                  <Form.SelectInput
                    value={displayUsageTermIndex}
                    // @ts-ignore
                    onChange={(e) =>
                      setDisplayUsageTermIndex(Number(e.target.value))
                    }
                  >
                    {weeklyUsageHistory.termList.map((week, weekIndex) => (
                      <option
                        key={`week_option_${weekIndex}`}
                        value={weekIndex}
                      >
                        {week.termString}
                      </option>
                    ))}
                    {/* @ts-ignore */}
                  </Form.SelectInput>
                  {weeklyUsageHistory.termList[displayUsageTermIndex] && (
                    <BarChart
                      items={
                        weeklyUsageHistory.termList[displayUsageTermIndex].list
                      }
                      unit="MB"
                      maxY={weeklyUsageHistory.maxRecord}
                    />
                  )}
                </>
              ) : (
                <Texts.Body2>利用履歴はありません</Texts.Body2>
              )}

              <Texts.Note>
                ※前日までのデータ通信量の目安です。{`\n`}
                ※速度制限中のデータ通信もカウントされます。
              </Texts.Note>
            </Boxes.White>
          </>
        )
      ) : (
        <>
          <Texts.H2>現在のデータ残量</Texts.H2>
          <Boxes.Gray>
            SIMを利用開始すると確認できるようになります。
            <br />
            <br />
            開通直後は画面の反映に時間がかかる場合があります。
            <br />
            しばらく時間をおいてから、もう一度アクセスしてください。
          </Boxes.Gray>
        </>
      )}
    </MenuLayout>
  );
};

export default IndexPage;
