// @ts-nocheck

import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typo } from '../../styles';
import Button from '../Common/Button';
import Icon from '../Common/Icon';
import { convertMBtoGB } from '../../utils/helpers';
import { device } from '../../styles/constants';

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.volume.border};
  border-radius: 10px;
  padding: 32px 32px 24px 32px;
  text-align: center;
  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  @media ${device.mobile} {
    padding: 16px;
  }
`;

const VolumeIcon = styled(Icon)`
  &&& {
    font-size: 46px;
    stop:first-child {
      stop-color: ${({ theme }) => theme.volume.gradient.start};
    }
    stop:last-child {
      stop-color: ${({ theme }) => theme.volume.gradient.end};
    }

    @media ${device.mobile} {
      font-size: 32px;
    }
  }
`;

const VolumePrefix = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.volume.text.primary};
  ${typo.XXL_B};

  @media ${device.mobile} {
    margin-left: 4px;
  }
`;

const VolumeNumber = styled.span`
  // @ts-ignore
  color: ${({ isWarning, theme }) =>
    isWarning ? theme.volume.text.warning : theme.volume.text.primary};
  font-size: 52px;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;

  @media ${device.mobile} {
    font-size: 48px;
  }
`;

const VolumeUnit = styled.span`
  // @ts-ignore
  color: ${({ isWarning, theme }) =>
    isWarning ? theme.volume.text.warning : theme.volume.text.primary};
  ${typo.XXL_B};
`;

const VolumeNotice = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  // @ts-ignore
  color: ${({ isWarning, theme }) =>
    isWarning ? theme.volume.text.warning : theme.volume.text.primary};
  ${typo.M};

  @media ${device.mobile} {
    ${typo.XS};
  }
`;

// @ts-ignore
const RemainingVolume = ({ volume }) => {
  const displayVolume = convertMBtoGB(volume);
  return (
    <Container>
      <div>
        <VolumeIcon name={Icon.ASSETS.COMMON.DATA} />
        <VolumePrefix>残り</VolumePrefix>
        {/* @ts-ignore */}
        <VolumeNumber isWarning={displayVolume < 1}>
          {displayVolume}
        </VolumeNumber>
        {/* @ts-ignore */}
        <VolumeUnit isWarning={displayVolume < 1}>GB</VolumeUnit>
      </div>
      {/* @ts-ignore */}
      <VolumeNotice isWarning={volume === 0}>
        {volume === 0
          ? 'データ通信速度に制限がかかっています。'
          : 'y.u mobileのギガは期限なしで利用できます。'}
      </VolumeNotice>
      <Link href="/mypage/charge">
        <Button.PrimarySub>データチャージ</Button.PrimarySub>
      </Link>
    </Container>
  );
};

RemainingVolume.propTypes = {
  volume: PropTypes.number.isRequired,
};

export default RemainingVolume;
